// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var popoverBody = Cx.cx([
      "bg-white",
      "rounded",
      "flex",
      "flex-col",
      "w-full"
    ]);

var popoverBodyLink = Cx.cx([
      "border-b",
      "border-gray-100",
      "py-3",
      "pl-5",
      "pr-2.5",
      "flex",
      "flex-row",
      "justify-between",
      "text-black",
      "text-sm",
      "font-medium",
      "tracking-normal",
      "leading-5",
      "last:border-b-0",
      "hover:text-teal"
    ]);

var popoverBodyLinkIcon = Cx.cx([
      "transform",
      "rotate-270"
    ]);

export {
  popoverBody ,
  popoverBodyLink ,
  popoverBodyLinkIcon ,
}
/* popoverBody Not a pure module */
