// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var pageContainer = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "mt-[104px]"
    ]);

var layout = Cx.cx([
      "grid",
      "md:min-w-[800px]",
      "lg:min-w-[1000px]",
      "max-w-[1200px]",
      "mx-10"
    ]);

var header = Cx.cx([
      "mt-5",
      "mb-2.5"
    ]);

var subHeader = Cx.cx([
      "flex",
      "justify-between",
      "md:mb-8"
    ]);

var title = Cx.cx(["m-0"]);

var searchField = Cx.cx([
      "py-2.5",
      "pl-[60px]",
      "min-w-96"
    ]);

var tableLink = Cx.cx([
      "text-teal",
      "font-semibold"
    ]);

var controlCell = Cx.cx([
      "flex",
      "items-center",
      "gap-1.5",
      "font-semibold",
      "text-gray-700"
    ]);

var label = Cx.cx([
      "m-0",
      "break-words",
      "hyphens-auto"
    ]);

var menu = Cx.cx([
      "flex",
      "flex-wrap",
      "items-center",
      "justify-start"
    ]);

var dropdown = Cx.cx([
      "grid",
      "grid-flow-col",
      "gap-4",
      "items-center"
    ]);

var dropdownBodyRow = Cx.cx([
      "grid",
      "!grid-cols-[max-content_1fr]",
      "gap-1.75",
      "items-center",
      "py-1.75",
      "border-b",
      "border-gray-100"
    ]);

var dropdownBodyLink = Cx.cx(["hover:text-teal"]);

var fetchError = Cx.cx([
      "text-fuxia",
      "text-center"
    ]);

var table = "mb-5";

var spinner = "min-h-40";

var dropdownBody = "min-w-60";

var pagination = "mb-10";

export {
  pageContainer ,
  layout ,
  header ,
  subHeader ,
  title ,
  searchField ,
  table ,
  tableLink ,
  controlCell ,
  label ,
  spinner ,
  menu ,
  dropdown ,
  dropdownBodyRow ,
  dropdownBody ,
  dropdownBodyLink ,
  fetchError ,
  pagination ,
}
/* pageContainer Not a pure module */
