// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var barWrapper = Cx.cx([
      "w-full",
      "bg-white",
      "shadow-[0_2px_3px_-1px_rgba(0,0,0,0.25)]",
      "relative"
    ]);

var barContainer = Cx.cx([
      "flex",
      "justify-start",
      "items-center",
      "mx-auto",
      "overflow-x-hidden",
      "overflow-y-hidden",
      "max-w-4xl"
    ]);

var linkContainer = Cx.cx([
      "border-b-3",
      "border-transparent",
      "mr-6"
    ]);

var linkItem = Cx.cx([
      "whitespace-nowrap",
      "text-sm",
      "font-semibold",
      "line-height-5",
      "block",
      "py-5",
      "hover:text-teal",
      "hover:decoration-0"
    ]);

var normallinkItem = Cx.cx([
      "text-gray-400",
      linkItem
    ]);

var underlinedLinkContainer = Cx.cx([
      "border-b-3",
      "border-purple",
      "mr-6"
    ]);

var underlinedLinkItem = Cx.cx([
      "text-black",
      "decoration-0",
      linkItem
    ]);

export {
  barWrapper ,
  barContainer ,
  linkContainer ,
  linkItem ,
  normallinkItem ,
  underlinedLinkContainer ,
  underlinedLinkItem ,
}
/* barWrapper Not a pure module */
