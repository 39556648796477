// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateSuspiciousIpRequest from "./requests/UpdateSuspiciousIpRequest.res.js";
import * as FetchSuspiciousIpsDashboardRequest from "./requests/FetchSuspiciousIpsDashboardRequest.res.js";

var update = UpdateSuspiciousIpRequest.exec;

var fetchDashboardIndex = FetchSuspiciousIpsDashboardRequest.exec;

export {
  update ,
  fetchDashboardIndex ,
}
/* UpdateSuspiciousIpRequest Not a pure module */
